import {createRouter, createWebHistory} from 'vue-router'
export default createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [{
        path: '/',
        name: 'home',
        component: () => import('../view/landing-page.vue')
    }, {
        path: '/properties', 
        name: 'properties',
        component: () => import('../view/properties-page.vue')
    }, {
        path: '/properties/control-panel',
        name: 'propertycontrolpanel',
        component: () => import('../view/properties-control-panel.vue')
    }, {
        path: '/properties/view-power-bundle',
        name: 'view-power-bundle',
        component: () => import('../view/view-power-bundle.vue')
    }, {
        path: '/qr-manage',
        name: 'qr-manage',
        component: () => import('../view/qr-manage.vue')
    }, {
        path: '/qrcodes-links',
        name: 'qrcodes-links',
        component: () => import ('../view/qrcodes-links.vue')
    }, {
        path: '/qrcodes/:id',
        name: 'create-qrcode',
        component: () => import ('../view/view-qrcode.vue')
    }, {
        path: '/contact-list/:id',
        name: 'contact-list',
        component: () => import('../view/view-contactlist.vue')
    }, {
        path: '/view-link/:id',
        name: 'view-link',
        component: () => import('../view/view-link.vue')     
    }, {
        path: '/add-contact/:id',
        name: 'add-contact',
        component: () => import('../view/add-contact.vue')     
    }, {
        path: '/print/:id',
        name: 'print-qrcode',
        component: () => import ('../view/print-qrcode.vue')
    }]
})