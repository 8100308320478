import { FETCH_QRCODE_LIST } from "./type.actions";
import { SET_QRCODE_LIST } from "./type.mutations";

import axios from "axios";
const initialState = {
    qrcodeList: [],
    qrcodeCount: 0,
    isLoading: false,
    insertId: 0
}

export const state = { ...initialState };

export const actions = {
    async [FETCH_QRCODE_LIST](context) {
        console.log(this);
        context.commit("setLoading", true);
        const token = JSON.parse(localStorage.getItem('query')).token
        const result = await axios.get('api/qrcodes?token=' + token);
        console.log(result);   
        context.commit(SET_QRCODE_LIST, result.data);
        context.commit("setLoading", false);
    },
    async ["deleteQrCode"] (context, id) {
        context.commit("setLoading", true);
        const token = JSON.parse(localStorage.getItem('query')).token
        const result = await axios.delete(`api/qrcodes/${id}?token=${token}`);
        if( result.data.success ) {
            context.commit("deleteQrCode", id);
            context.commit("setLoading", false);
        }
        
    },

    async ["updateLink"] (context, formData) {
        const token = JSON.parse(localStorage.getItem('query')).token
        const result = await axios.put('api/qrcodes?token=' + token, formData);
        if( result.data.success ) {
            context.commit("updateLink", formData);
        }
    },

    async ["createQRCode"] (context, data) {
        const token = JSON.parse(localStorage.getItem('query')).token
        const result = await axios.put(`api/qrcodes?token=` + token, data);
        
        if( result.data.success ) {
            data = result.data;
            console.log(data);
            context.commit("creteQRCode", data);
        }
    }
};

export const mutations = {
    [SET_QRCODE_LIST](state, value) {
        console.log("mutation", value);
        state.qrcodeCount = value.length;
        state.qrcodeList = value;
    }, 
    ["setLoading"](state, value) {
        state.isLoading = value; 
    },
    ["deleteQrCode"](state, value) {
        const index = state.qrcodeList.findIndex(item => item.id == value);
        if( index === -1 ) return;
        state.qrcodeList.splice(index, 1);
        state.qrcodeCount = state.qrcodeList.length;
    },
    ["creteQRCode"](state, value)  {
        console.log("PUSHING Data", value)
        state.qrcodeList.push(value);
        state.insertId = value.id;  
        state.qrcodeCount ++;
    },
    ["updateLink"](state, value) {
        const index = state.qrcodeList.findIndex(item => item.id == value);
        if( index === -1 ) return;
        state.qrcodeList[index] = value;
    }

};


export const getters = {
    qrcodeList(state) {
        console.log('getter', state.qrcodeList)
        return state.qrcodeList;
    },

    qrcodeCount(state) {
        console.log('getter', state.qrcodeList)
        return state.qrcodeCount;
    },

    isLoading(state) {
        return state.isLoading;
    },
    insertId(state) {
        return state.insertId;
    }
}


export default {
    state,
    actions,
    mutations,
    getters
};
